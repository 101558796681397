<template>
  <v-btn
    v-on:click="paginatePage"
    color="primary"
    block
    :disabled="currentPage === lastPage"
    :loading="isLoadingMoreData"
  >
    <span
      v-if="currentPage === lastPage && numberOfItems != 0"
      class="text--primary">
      There are no more orders to see - we only display orders up to 60 days.
    </span>
    <span v-if="currentPage != lastPage">Load more</span>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LazyLoading",
  props: [
    "store",
    "collection",
    "searchStr",
    "sortBy",
    "numberOfItems",
    "filters",
  ],
  data: () => ({
    isLoadingMoreData: false,
  }),
  computed: {
    ...mapGetters("shop", ["currentShop"]),

    currentPage: {
      get() {
        if (
          this.$store.state[this.store][this.collection].pagination != undefined
        ) {
          return this.$store.state[this.store][this.collection].pagination
            .current_page;
        }
        return 1;
      },
      // set(value) {
      //   return this.$store.commit(`${this.store}/${SET_CURRENT_PAGE}`, value, {
      //     root: true,
      //   });
      // },
    },

    lastPage: {
      get() {
        if (
          this.$store.state[this.store][this.collection].pagination != undefined
        ) {
          return this.$store.state[this.store][this.collection].pagination
            .last_page;
        }
        return 1;
      },
    },
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    paginatePage() {
      this.isLoadingMoreData = true;
      let pageNumber = this.currentPage + 1;
      let modifiedSearchString = this.searchStr.replace(/[^\w\s]/gi, "");

      this.$store.dispatch(`${this.store}/fetch${this.capitalizeFirstLetter(this.collection)}List`, {
        currentShopId: this.currentShop.id,
        searchStr: modifiedSearchString,
        sortBy: this.sortBy,
        pageNumber: pageNumber,
        filters: this.filters,
      }).finally(() => {
        this.isLoadingMoreData = false;
      });
    },
  }
};
</script>
