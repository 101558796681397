<template>
<div class="auto-push-toggle-wrapper float-right">
    <div class="toggle-text-wrapper d-flex align-center">
        <div class="float-left toggle-text d-flex">
            <span>Automated Push </span>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" class="ml-1">mdi-help-circle-outline</v-icon>
                </template>
                Turn on this toggle to activate automated push.
            </v-tooltip>
        </div>
        <v-switch
            v-model="autoPushToggle.value"
            inset
            dense
            hide-details
            color="blue darken-1"
            :label="getAutoPushToggleLabel()"
            class="float-left pt-0 mt-0 pl-4"
            @click="clickAutoSwitch"
        >
        </v-switch>
    </div>
    <v-overlay v-if="autoPushToggle.isSwitchDirty">
        <v-card
            light
            class="container pl-5 pr-5 overflow-hidden auto-push-content"
            v-if="this.pushSettings != null"
        >
            <v-card-title>
                <h2>Your orders will be automatically pushed with these settings:</h2>
            </v-card-title>
            <div class="pa-5">
                <ol class="mt-0 mb-5">
                    <li v-for="config in pushSettingSummary"
                        :key="config.id"
                    >
                        <div v-if="config.key === 'push_order_email_option' && autoPushToggle.value" class="mb-5">
                            <h3>E-mail Contact Method:</h3>
                            <span v-if="config.value === 'custom_email'">
                                Push order e-mail with {{ config.custom_value }}
                            </span>
                            <span v-else-if="config.value === 'destination_store_email'">
                                Push order email with destination store e-mail ({{ currentShop.email }})
                            </span>
                            <span v-else>
                                Push order with customer email from order
                            </span>
                        </div>
                        <div v-else-if="config.key === 'push_order_shipping_rules'">
                            <h3>Shipping Rate Rules:</h3>
                            <ul class="pl-0 pt-0 pr-5 ml-5">
                                <li v-for="(shippingRule, index) in config.value"
                                    :key="index"
                                    class="pl-0"
                                >
                                  ${{ parseFloat(shippingRule.shipping_cost).toFixed(2) }} for orders from
                                  <span v-if="(typeof(config.value[index +1]) != 'undefined')" class="ml-1">
                                    ${{ parseFloat(shippingRule.min_order_total).toFixed(2) }} to ${{ parseFloat(config.value[index +1].min_order_total).toFixed(2) }}
                                    </span>
                                  <span class="ml-1" v-else>
                                    ${{ parseFloat(shippingRule.min_order_total).toFixed(2) }} and up
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ol>
                <div>Only paid orders will be pushed. <a href="http://help.syncio.co/en/articles/4163480-coming-soon-order-sync-upgrade" target="_blank">Learn More</a></div>
            </div>
            <v-card-actions
                class="float-right"
                v-if="isSaving"
            >
                Saving...
            </v-card-actions>
            <v-card-actions
                class="float-right"
                v-else
            >
                <v-btn
                    text
                    class="action-btn"
                    @click="closeAutoPushSwitchAlert()"
                >
                    Cancel
                </v-btn>
                <v-btn
                    outlined
                    class="action-btn"
                    @click="redirectToSettings()"
                >
                    Change Settings
                </v-btn>
                <v-btn
                    dark
                    outlined
                    class="action-btn primary"
                    @click="toggleAutoPush()"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else
            light
            class="container pl-5 pr-5 overflow-hidden auto-push-content"
        >
              <v-row
                  class="overflow-hidden"
              >
                <v-col class="text-center" style="margin-top: 100px">
                  <v-progress-circular
                      indeterminate
                      color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
        </v-card>
    </v-overlay>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { AUTO_PUSH_TOGGLE, FETCH_PUSH_SETTINGS, ADD_NOTIFICATION } from "@/store/actions.type";

export default {
    name: "AutoPushSwitchComponent",
    data: function() {
        return {
            isSaving: false,
            autoPushToggle: {
                value: false,
                isSwitchDirty: false,
            },
        };
    },
    created() {
        this.autoPushToggle.value = null;
        this.$store.dispatch(`orders/${FETCH_PUSH_SETTINGS}`, { storeId: this.currentShop.id})
            .then((data) => {
                if (data.success) {
                    if (typeof data.settings != 'undefined') {
                        this.autoPushToggle.value = this.pushSettings.auto_push_order;
                        data.settings.forEach((setting) => {
                            if (setting.key === 'auto_push_order') {
                                this.autoPushToggle.value = setting.value;
                            }
                        });
                    }
                }
            });
    },
    computed: {
        ...mapState("orders", ["pushSettings"]),
        ...mapGetters("shop", ["currentShop"]),
        pushSettingSummary() {
            let visibleSettings = [];
            if (this.pushSettings != null) {
                this.pushSettings.forEach((setting) => {
                    if (setting.key != 'auto_push_order') {
                        visibleSettings.push(setting);
                    }
                });
            }
            return visibleSettings;
        },
    },
    methods: {
        getAutoPushToggleLabel() {
            if (this.autoPushToggle.value) {
                return "ON";
            } else {
                return "OFF";
            }
        },
        closeAutoPushSwitchAlert() {
            if (this.pushSettings != null) {
                this.pushSettings.forEach((setting) => {
                    if (setting.key === 'auto_push_order') {
                        this.autoPushToggle.value = setting.value;
                    }
                });
            }
            this.autoPushToggle.isSwitchDirty = false;
        },
        toggleAutoPush() {
            this.isSaving = true;
            this.$store.dispatch(`orders/${AUTO_PUSH_TOGGLE}`, { storeId: this.currentShop.id})
                .then(() => {
                    this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                        notification: {
                            id: 'N' + (Math.floor(Math.random() * 100000000)),
                            position: "bottom-left",
                            type: "success",
                            body: "Auto push has been turned "+this.getAutoPushToggleLabel()+".",
                            state: 0,
                            length: 6000, // seconds
                            data: null,
                        },
                    }, {root:true});
                    this.$store.dispatch(`orders/${FETCH_PUSH_SETTINGS}`, { storeId: this.currentShop.id})
                        .then((data) => {
                            if (data.success) {
                                if (data.settings != null) {
                                    data.settings.forEach((setting) => {
                                        if (setting.key === 'auto_push_order') {
                                            this.autoPushToggle.value = setting.value;
                                        }
                                    });
                                }
                            }
                            this.autoPushToggle.isSwitchDirty = false;
                            this.isSaving = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isSaving = false;
                        });
                });
        },
        redirectToSettings() {
            this.$router.push({ name: "PushOrderSettingsPage" });
        },
        clickAutoSwitch(){
            if (!this.autoPushToggle.value) {
                this.toggleAutoPush();
            } else {
                this.autoPushToggle.isSwitchDirty = true;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.auto-push-toggle-wrapper {
    .toggle-text-wrapper {
        background-color: #fff;
        overflow: hidden;
        padding: 5px 20px;
        margin-right: 24px;
        border-radius: 4px;
        height: 45px;

        .toggle-text {
            line-height: 29px;
        }
    }
    .action-btn {
        margin-left: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .auto-push-content {
        min-width: 736px;
        min-height: 283px;
    }
}
</style>