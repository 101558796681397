<template>
  <v-container class="order-management-page">
    <v-row v-if=" isLoadingOnPage || (currentShop != null && currentShop.type != 'source' && currentActivePlan === null)">
      <v-col>
        <data-preparation-overlay></data-preparation-overlay>
      </v-col>
    </v-row>
    <v-row v-else>
      <order-module-upgrade-dialog></order-module-upgrade-dialog>

      <div class="fixed-banner" v-if="showBulkSyncBanner && ordersMetaData.bulk_push_count > 0">
        <v-icon color="#FCB058" class="mr-4 sync-icon">sync</v-icon>
        Bulk orders push in progress, you may close this window.
        <v-btn class="ml-4" fab x-small elevation="0" @click="hideSyncBanner">
          <v-icon color="rgba(0, 0, 0, 0.87)">mdi-close</v-icon>
        </v-btn>
      </div>

      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col class="title">
                <span class="text-h1 text--primary">Orders</span>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col cols="6">
                <p class="small-body text-no-wrap">Push your orders with synced products to connected source stores</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col>
                <div class="mb-3 overflow-hidden push-setting-tools">
                  <v-btn
                    color="primary"
                    dark
                    elevation="0"
                    class="float-right push-setting-btn"
                    @click="redirectToPushOrderSettingsPage()"
                  >
                    <div class="d-flex align-center">
                      <v-icon class="mr-2" size="20px">mdi-cog</v-icon>Push Settings
                    </div>
                  </v-btn>
                  <auto-push-switch-component></auto-push-switch-component>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="status-filters d-none">
          <v-col cols="12">
            <ul class="status-filters-list">
              <li
                :class="{ active: filter === pushStatusFilter }"
                :key="filter"
                @click="pushOrderStatusHandler(filter)"
                v-for="filter in pushStatusFilters"
              >
                <template v-if="filter === 'all'">
                  All orders
                </template>
                <template v-else-if="filter === 'not_pushed'">
                  Not pushed
                </template>
                <template v-else>
                  {{ filter }}
                </template>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col class="pt-0">
            <v-card color="white" class="card-rounded not-from-top pt-3" :disabled="isLoading">
              <v-row class="pb-1">
                <v-col cols="10" class="d-flex pr-0">
                  <div class="pl-4">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="fetchOrders"
                          class="refresh-btn"
                        >
                          <v-icon class="refresh-icon">refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh Table</span>
                    </v-tooltip>
                  </div>
                  <v-text-field
                    :disabled="isLoading"
                    @click:append="resetSearch"
                    append-icon="clear"
                    class="search-bar"
                    dense
                    height="36px"
                    outlined
                    single-line
                    hide-details=""
                    v-model="searchStr"
                    v-on:keyup.enter="fetchOrders"
                  >
                    <template v-slot:label>
                      <span class="search-bar-label">Search by exact order number (e.g: #1234)</span>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-icon class="mt-1" size="19px">search</v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="2" class="pl-0">
                  <v-select
                    v-model="defaultSorting"
                    outlined
                    single-line
                    class="sort-bar"
                    dense
                    :items="sortByItems"
                    item-text="name"
                    item-value="display"
                    disable-lookup
                    hide-details
                  >
                    <template v-slot:append>
                      <v-icon
                        style="transform: translateY(2px);"
                        size="0"
                        color="#000000"
                        >sort</v-icon
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <div class="sortRadioButtons">
                        <v-radio-group
                          v-model="sortBy"
                          v-on:change="fetchOrders"
                          hide-details="true"
                        >
                          <v-radio :value="item.value">
                            <template v-slot:label>
                              <div
                                v-text="item.name"
                                v-if="item.value === sortBy"
                                class="selected-sort-text"
                              ></div>
                              <div v-text="item.name" v-else></div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </template>
                    <template v-slot:selection>
                      <div class="sort-label w-100">
                        Sort:
                        <strong
                          style="color: rgba(0,0,0,.87)"
                          class="float-right"
                          >{{
                            defaultSorting.display || defaultSorting
                          }}</strong
                        >
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3" class="pl-0" v-if="showDateRangeFilter">
                  <date-range-picker
                    ref="picker"
                    :locale-data="{
                      firstDay: 1,
                      format: 'yyyy-mm-dd',
                      separator: ' to ',
                    }"
                    v-model="dateRange"
                    format="yy-mm-dd"
                    opens="left"
                    :minDate="minDateForDateRange"
                    :maxDate="maxDateForDateRange"
                    :showDropdowns="false"
                    :ranges="ranges"
                    :rangeText="rangeText"
                    @update="fetchOrders"
                  >
                  <template v-slot:input="picker">
                    {{ picker.rangeText }}
                  </template>
                  </date-range-picker>
                </v-col>
              </v-row>

              <v-row v-if="selectedOrdersIDs.length > 0 && !ordersMetaData.bulk_push" class="px-5 py-1">
                <v-col>
                  <strong>
                    {{ selectedOrdersIDs.length }} <span v-if="selectedOrdersIDs.length > 1">orders</span> <span v-else>order</span>  selected
                  </strong>
                  <v-btn color="primary" elevation="0" class="ml-4" style="border-radius: 4px !important;" @click="bulkOrderPushDialogHandler">
                    Push Order <span v-if="selectedOrdersIDs.length > 1">s</span>
                  </v-btn>
                </v-col>
              </v-row>

              <div class="jobs-in-progress" v-if="(ordersMetaData.bulk_push_count > 0 && ordersMetaData.bulk_push)">
                <div class="jobs-in-progress-inner" @click="fetchOrders">
                  <v-icon color="#FD8800" small class="mr-1">sync</v-icon> {{ ordersMetaData.bulk_push_count }} order push in progress &nbsp; | <span class="text-uppercase d-inline-block" style="vertical-align: middle;">Click to refresh</span>
                </div>
              </div>

              <v-data-table
                :loading="isLoading"
                :headers="headers"
                :items="orders"
                :item-class="itemClass"
                class="mt-1 elevation-1 order-table row-hover"
                hide-default-footer
                item-key="id"
                :items-per-page="getTotalOrderCounts()"
                v-on:click:row="loadCurrentOrder"
                show-select
                @toggle-select-all="selectOrdersHandler"
                @input="selectOrderHandler"
                ref="orderTable"
              >
                <template v-slot:header.name="{ header }">
                  <div class="header-text">{{ header.text }}</div>
                </template>

                <!-- <template v-slot:header.unread_message_count="{ header }">
                  <div class="header-text">{{ header.text }}</div>
                </template> -->

                <template v-slot:header.created_at="{ header }">
                  <div class="header-text">{{ header.text }}</div>
                </template>

                <template v-slot:header.customer_name="{ header }">
                  <div class="header-text">{{ header.text }}</div>
                </template>

                <template v-slot:header.push_status="{ header }">
                  <div class="header-text text-center">{{ header.text }}</div>
                </template>

                <template v-slot:header.line_items="{ header }">
                  <div class="header-text text-center">{{ header.text }}</div>
                </template>

                <template v-slot:header.source_stores="{ header }">
                  <div class="header-text text-center">{{ header.text }}</div>
                </template>

                <template v-slot:header.id="{}">
                  <div class="header-text"></div>
                </template>

                <template v-slot:item.name="{ item }" class="td-1">
                  <div class="table-text">{{ item.name }}</div>
                </template>

                <!-- <template v-slot:item.unread_message_count="{ item }">
                  <div class="table-text">
                    <template v-if="item.is_message_initiated">
                      <v-icon size="18px" :color="item.unread_message_count > 0 ? '#0E3B4D' : '#858585'">message</v-icon>
                      <span v-if="item.unread_message_count > 0" class="message-count-indicator">
                        {{ item.unread_message_count }}
                      </span>
                    </template>
                  </div>
                </template> -->

                <template v-slot:item.created_at="{ item }">
                  <div class="table-text">
                    {{ new Date(item.created_at).toLocaleString("en-US", {timeZone: "Australia/Sydney"}) | moment("DD MMM [at] hh:mm A") }}
                  </div>
                </template>

                <template v-slot:item.customer_name="{ item }">
                  <div
                    class="table-text red--text font-italic font-weight-bold"
                    v-if="item.customer_name === null"
                  >
                    no customer
                  </div>
                  <div
                    class="table-text red--text font-italic font-weight-bold"
                    v-else-if="item.customer_name.match(/^\s*$/) != null"
                  >
                    no customer
                  </div>
                  <div class="table-text" v-else>{{ item.customer_name }}</div>
                </template>

                <template v-slot:item.push_status="{ item }">
                  <div class="table-text text-center">
                    <span v-if="isStatusPending(item)" class="pending-status">
                      <span class="indicator"></span> Pending
                    </span>
                    <v-chip class="align-center justify-center" :class="getPushStatusClass(item.push_status)" v-else-if="!item.order_fail_reason">
                      <v-icon class="chip-icon-color">
                        {{ getPushStatusIcons(item.push_status) }}
                      </v-icon>
                      <span class="ml-2 chip-font">{{ getPushStatus(item.push_status) }}</span>
                    </v-chip>
                    <v-tooltip right v-else color="error" dark max-width="225px" transition="scroll-x-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" class="align-center justify-center" :class="getPushStatusClass(item.push_status)">
                        <span class="ml-2 chip-font">{{ getPushStatus(item.push_status) }}</span>
                        <v-icon class="chip-icon-color" size="20px">mdi-chevron-right</v-icon>
                      </v-chip>
                      </template>
                      <span>{{ item.order_fail_reason }}</span>
                    </v-tooltip>
                  </div>
                </template>

                <template v-slot:item.line_items="{ item }">
                  <div class="table-text text-center">
                    {{ getItemCount(item.line_items) }}
                  </div>
                </template>

                <!-- <template v-slot:item.source_stores="{ item }">
                  <div class="table-text text-center">
                    {{ item.source_stores }}
                  </div>
                </template> -->

                <template v-slot:item.id="{}">
                  <div class="table-text on-view-order-button d-flex align-center justify-end">
                    <span>View Order</span>
                    <v-icon class="ml-2">chevron_right</v-icon>
                  </div>
                </template>

                <template v-slot:no-data>
                  <div class="mt-16 mb-16">
                    <v-icon size="80px" color="#939393">search</v-icon>
                    <div v-if="searchStr === '' && getTotalOrderCounts() === 0">
                      <div
                        v-text="`There are no orders yet.`"
                        class="no-order-big-text"
                      ></div>
                      <div
                        v-text="
                          `Start by creating an order on Shopify or try to refresh the page.`
                        "
                        class="no-order-small-text"
                      ></div>
                    </div>
                    <div v-else>
                      <div
                        v-text="`There are no orders to see.`"
                        class="no-order-big-text"
                      ></div>
                      <div
                        v-text="
                          `Try changing your search term. We only display orders up to 60 days.`
                        "
                        class="no-order-small-text"
                      ></div>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <lazy-loading
              store="orders"
              collection="orders"
              :search-str="searchStr"
              :sort-by="sortBy"
              :number-of-items="getTotalOrderCounts()"
              :filters="filters"
            >
            </lazy-loading>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="this.showSingleOrder">
      <v-overlay>
        <v-scroll-x-reverse-transition>
          <order-details-block :orderActiveStatus="orderActiveStatus"></order-details-block>
        </v-scroll-x-reverse-transition>
      </v-overlay>
    </v-row>

    <v-overlay :value="showBulkOrderPushDialog" class="dialog bulk-order-push-dialog">
      <v-card light class="card-rounded not-a-link" width="675px" v-click-outside="closeOuterDialogHandler">
        <v-toolbar height="71">
          <v-container class="px-0 d-flex justify-space-between">
            <v-card-title class="h3 pa-0 text-capatilize">
              {{ selectedOrdersIDs.length }} orders selected for bulk push
            </v-card-title>
            <v-icon size="30px" color="#ffffff" class="mt-1" @click="closeOuterDialogHandler">close</v-icon>
          </v-container>
        </v-toolbar>

        <div class="dialog-body pa-10">
          <v-form v-model="valid">
            <div class="d-block pb-6" style="border-bottom: solid 1px #ccc;">
              <v-switch @change="pushWithExistingShippingRulesHandler" hide-details="" v-model="pushWithExistingShippingRules" class="mt-0" inset dense color="blue darken-1" label="Bulk Push With Existing Shipping Rules"></v-switch>
            </div>
            <div class="d-flex justify-space-between pt-5">
              <v-switch @change="pushWithCustomRateHandler" hide-details="" v-model="pushWithCustomRate" class="mt-2" inset dense color="blue darken-1" label="Bulk Push With Custom Rate"></v-switch>
              <v-text-field :disabled="!pushWithCustomRate" :rules="rules" v-model.number="shippingCost" solo label="Enter a shipping fee (can be $0)" prepend-inner-icon="mdi-currency-usd" class="ml-5 custom-shipping-rate-input"></v-text-field>
            </div>
            <div class="d-flex justify-end mt-6 align-center">
              <v-btn @click="bulkPushOrders" color="primary" :disabled="!valid && !pushWithExistingShippingRules" class="push-order-btn mr-6" elevation="0">Push Orders</v-btn>
              <span class="btn-dismiss pointer" @click="closeOuterDialogHandler">Cancel</span>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { FETCH_CURRENT_ORDER, FETCH_ORDERS } from "@/store/actions.type";
import { mapGetters, mapState } from "vuex";
import AutoPushSwitchComponent from "@/views/order/components/AutoPushSwitchComponent";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";
import DateRangePicker from "vue2-daterange-picker";
import LazyLoading from "@/views/order/components/LazyLoading";
import moment from "moment";
import OrderDetailsBlock from "@/views/order/components/OrderDetailsBlock";
import OrderModuleUpgradeDialog from "@/views/order/components/OrderModuleUpgradeDialog.vue";

export default {
  name: "OrderManagementPage",

  components: {
    AutoPushSwitchComponent,
    DataPreparationOverlay,
    DateRangePicker,
    LazyLoading,
    OrderDetailsBlock,
    OrderModuleUpgradeDialog
  },

  data: () => ({
    rules: [
      v => /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(v) || 'Must be a valid number greater or equal to 0.0'
    ],
    valid: false,
    isLoadingOnPage: false,
    searchStr: "",
    sortBy: "DESC",
    headers: [
      { text: "Order", value: "name", sortable: false, width: "10%" },
      // { text: "Messages", value: 'unread_message_count', sortable: false, width: "12.5%" },
      { text: "Date (AEST)", value: "created_at", sortable: false, width: "20%" },
      { text: "Customer", value: "customer_name", sortable: false, width: "30%" },
      { text: "Push Status", value: "push_status", sortable: false, width: "12.5%" },
      { text: "Synced Items", value: "line_items", sortable: false, width: "12.5%" },
      // { text: "Source Stores", value: "source_stores", sortable: false, width: "12.5%" },
      { text: "Actions", value: "id", sortable: false, width: "15%" }
    ],
    defaultSorting: {
      name: "Date: Newest First",
      value: "DESC",
      display: "Newest",
    },
    sortByItems: [
      { name: "Date: Newest First", value: "DESC", display: "Newest" },
      { name: "Date: Oldest First", value: "ASC", display: "Oldest" },
    ],
    pushStatusFilters: ["all", "pushed", "not_pushed"],
    pushStatusFilter: "all",
    dateRange: {
      startDate: moment(moment().subtract(59, "days")._d).format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    orderActiveStatus: '',
    filters: [],
    selectedOrdersIDs: [],
    showBulkOrderPushDialog: false,
    pushWithExistingShippingRules: true,
    pushWithCustomRate: false,
    shippingCost: null,
    showBulkSyncBanner: false,
    localStorage: {},
    showDateRangeFilter: false
  }),

  computed: {
    ...mapGetters("orders", [
      "orders",
      "isLoading",
      "currentOrder",
      "showSingleOrder",
    ]),

    ...mapGetters("shop", ["currentShop"]),
    ...mapState("plan", ["currentActivePlan"]),
    ...mapState("orders", ["ordersMetaData"]),

    maxDateForDateRange() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    minDateForDateRange() {
      let today = new Date();
      let lastSixtyDays = new Date();
      lastSixtyDays.setDate(today.getDate() - 59);
      lastSixtyDays.setHours(0, 0, 0, 0);
      return lastSixtyDays;
    },
    ranges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let lastSixtyDays = new Date();
      lastSixtyDays.setDate(today.getDate() - 59);
      lastSixtyDays.setHours(0, 0, 0, 0);

      let lastSevenDays = new Date();
      lastSevenDays.setDate(today.getDate() - 6);
      lastSevenDays.setHours(0, 0, 0, 0);

      return {
        All: [lastSixtyDays, today],
        Today: [today, today],
        "7 Days": [lastSevenDays, today],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      };
    },
    rangeText() {
      return 'Hello'
    }
  },

  created() {
    this.$store.dispatch(`shop/init`);
    this.isLoadingOnPage = true;
    this.$store.dispatch(`shop/loadCurrentShop`).then((data) => {
      if (data.success === true) {
        if (
          this.currentShop != null &&
          this.currentShop.type != "source" &&
          (this.currentActivePlan === null ||
            typeof this.currentActivePlan === "undefined")
        ) {
          this.$store.dispatch("plan/getCurrentPlan").finally(() => {
            this.fetchOrders();
            this.isLoadingOnPage = false;
          });
        } else {
          this.fetchOrders();
          this.isLoadingOnPage = false;
        }
      }
    })
    .catch(() => {
      this.isLoadingOnPage = false;
    });

    if(this.$route.query.status) {
      this.pushStatusFilter = this.$route.query.status;
    }

    this.localStorage = window.localStorage;
  },

  methods: {
    isStatusPending(item) {
      if(this.localStorage[`selectedOrders_${this.currentShop.id}`] && this.localStorage[`selectedOrders_${this.currentShop.id}`].includes(item.order_ref_id) && this.ordersMetaData.bulk_push && item.push_status !== 'pushed') {
        return true;
      } else if(this.localStorage[`selectedOrders_${this.currentShop.id}`] && this.localStorage[`selectedOrders_${this.currentShop.id}`].includes(item.order_ref_id) && this.ordersMetaData.bulk_push && item.push_status === 'pushed') {
        let updatedLocalStorage = JSON.stringify(this.localStorage[`selectedOrders_${this.currentShop.id}`]).replace(item.order_ref_id, '');
        localStorage.setItem(`selectedOrders_${this.currentShop.id}`, updatedLocalStorage);
        return false;
      }
    },

    selectOrdersHandler(data) {
      if(!data.value) {
        this.selectedOrdersIDs = [];
        return;
      }

      let selectedItems = [];
      selectedItems = data.items.filter(order => {
        if(order.push_status === 'pushed' || +order.source_stores === 0 || (order.order_active_status === 'Order Cancelled' || order.order_active_status === 'order cancelled')) { return false; }
        return true;
      }).map(order => {
        return order.order_ref_id;
      });

      this.selectedOrdersIDs = [...this.selectedOrdersIDs, ...selectedItems];
    },

    selectOrderHandler(items) {
      this.selectedOrdersIDs = [];
      let selectedItems = [];
      selectedItems = items.filter(order => {
        if(order.push_status === 'pushed' || +order.source_stores === 0 || (order.order_active_status === 'Order Cancelled' || order.order_active_status === 'order cancelled')) { return false; }
        return true;
      }).map(order => {
        return order.order_ref_id;
      });

      this.selectedOrdersIDs = [...this.selectedOrdersIDs, ...selectedItems];
    },

    bulkOrderPushDialogHandler() {
      this.showBulkOrderPushDialog = true;
    },

    async bulkPushOrders() {
      let shippingCost = null;
      if(this.pushWithExistingShippingRules) {
        shippingCost = null;
      } else if(this.pushWithCustomRate) {
        shippingCost = this.shippingCost;
      }

      this.postBulkPushOperations();

      await this.$store.dispatch('orders_2_0/bulkPush', {
        store_Id: this.currentShop.id,
        shipping_cost: shippingCost,
        order_ref_ids: this.selectedOrdersIDs
      });

      await this.fetchOrders();
      this.$refs.orderTable._data.selection = {};
    },

    postBulkPushOperations() {
      localStorage.setItem(`selectedOrders_${this.currentShop.id}`, this.selectedOrdersIDs);
      this.showBulkOrderPushDialog = false;
      this.showBulkSyncBanner = true;
    },

    closeOuterDialogHandler() {
      this.showBulkOrderPushDialog = false;
    },

    pushWithExistingShippingRulesHandler(value) {
      this.pushWithExistingShippingRules = value;
      this.pushWithCustomRate = !value;
    },

    pushWithCustomRateHandler(value) {
      this.pushWithCustomRate = value;
      this.pushWithExistingShippingRules = !value;
    },

    hideSyncBanner() {
      this.showBulkSyncBanner = false;
    },

    itemClass(item) {
      let className = 'row-hover ';
      if(item.order_active_status.toLowerCase() === 'order active') {
        className += 'order-active'
      } else if(item.order_active_status.toLowerCase() === 'order cancelled') {
        className += 'order-cancelled'
      }

      if(item.push_status.toLowerCase() === 'pushed') {
        className += ' pushed';
      }

      if(item.source_stores == "0") {
        className += ' no-source-store';
      }

      if(this.selectedOrdersIDs.includes(item.order_ref_id) && this.ordersMetaData.bulk_push && item.push_status !== 'invalid') {
        className += ' pending';
      }

      return className;
    },

    getTotalOrderCounts() {
      if (this.orders != undefined) {
        return this.orders.length;
      }
      return 0;
    },
    getItemCount(items) {
      return items.length > 1
        ? items.length + " items"
        : items.length + " item";
    },
    resetSearch() {
      this.searchStr = "";
      this.fetchOrders();
    },
    async fetchOrders() {
      let modifiedSearchString = this.searchStr.replace("#", "--SYN--");
      this.filters = [];

      if (this.pushStatusFilter) {
        this.filters.push({ status: this.pushStatusFilter });
      }

      // if (this.dateRange) {
      //   let startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
      //   let endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      //   let rangeFilter = `${startDate} to ${endDate}`;
      //   this.filters.push({ date_range: rangeFilter });
      // }

      await this.$store.dispatch(`orders/${FETCH_ORDERS}`, {
        currentShopId: this.currentShop.id,
        searchStr: modifiedSearchString,
        sortBy: this.sortBy,
        pageNumber: 1,
        filters: this.filters,
      });
    },

    async loadCurrentOrder(item) {
      this.orderActiveStatus = item.order_active_status;
      await this.$store.dispatch(`orders/${FETCH_CURRENT_ORDER}`, {
        storeId: this.currentShop.id,
        orderId: item.id,
      });
    },

    redirectToPushOrderSettingsPage() {
      this.$router.push({ name: "PushOrderSettingsPage" });
    },
    getPushStatusClass(pushStatus) {
      let pushStatusClasses = {
        not_pushed: "not-push-chip",
        pushed: "push-chip",
        failed: "failed-chip",
        invalid: "failed-chip",
        partially_pushed: "partially-push-chip",
      };
      return pushStatusClasses[pushStatus];
    },
    getPushStatus(pushStatus) {
      let pushStatusText = {
        not_pushed: "Not Pushed",
        pushed: "Pushed",
        failed: "Failed",
        invalid: "Invalid",
        partially_pushed: "Partially Pushed",
      };
      return pushStatusText[pushStatus];
    },
    getPushStatusIcons(pushStatus) {
      let pushStatusIcons = {
        not_pushed: "trip_origin",
        pushed: "circle",
        failed: "report_problem",
        invalid: "report_problem",
        partially_pushed: "tonality",
      };
      return pushStatusIcons[pushStatus];
    },
    async pushOrderStatusHandler(filter) {
      this.pushStatusFilter = filter;
      let query = { status: filter };
      this.$router.replace({ query })
      await this.fetchOrders();
      this.selectedOrdersIDs = [];
      this.$refs.orderTable._data.selection = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.text-h1.text--primary {
  color: #0e3b4d !important;
}
.title {
  display: flex;
  vertical-align: middle;
}
.medium-body-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 18.9px;
}
th {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
  color: #0e3b4d;
}
.header-text {
  color: #0e3b4d;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  opacity: 1;
  text-align: left;
}
.table-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #000;
}
.status-font-style {
  vertical-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #495057;
  margin: 0px 3px;
}
.push-order-button {
  border: 1px solid #495057;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.push-order-button:hover {
  border: 1px solid #495057;
  background-color: #495057;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 4px 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: white;
}
.refresh-button {
  padding: 8px 0 16px 16px;
}
.search-bar {
  padding: 8px 16px 8px 8px;
  border-radius: 10px;
}
.search-bar-label {
  font-size: 13px;
  line-height: 18px;
  margin: 0px 14px;
}

.push-setting-tools {
  min-width: 430px;
  .push-setting-btn {
    height: 45px;
    border-radius: 4px !important;
  }
}
.no-order-big-text {
  font-weight: 600;
  font-size: 24.88px;
  line-height: 34px;
  color: #000000 !important;
  margin-top: 9px;
}
.no-order-small-text {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  padding: 10px;
  color: #000000 !important;
}
.sort-bar {
  padding: 8px 12px 0 8px;
  border-radius: 10px;
  width: 100%;
}
.sort-label {
  color: #495057;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
}
.sortRadioButtons {
  width: 201px;
  height: 50px;
  margin-top: -5px !important;
}
.selected-sort-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
}

::v-deep .v-list-item--active::before {
  background-color: #ffffff;
}

.push-chip,
.partially-push-chip,
.not-push-chip,
.failed-chip {
  background: #ecffec !important;
  border-radius: 10px;
  height: 24px;
  justify-content: center;
  text-align: center !important;
  vertical-align: center;

  .chip-icon-color {
    font-size: 9px;
  }

  .chip-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
  }
}

.push-chip {
  .chip-icon-color {
    color: #28a228 !important;
  }
  .chip-font {
    color: #085f07;
  }
}

.partially-push-chip {
  background: #fff2e3 !important;
  .chip-icon-color {
    color: #aa5200 !important;
  }
  .chip-font {
    color: #aa5200;
  }
}

.not-push-chip {
  background: #fcb058 !important;
  .chip-icon-color {
    color: #495057 !important;
  }
  .chip-font {
    color: #495057;
  }
}

.failed-chip {
  background: #ffecec !important;
  .chip-icon-color {
    color: #ff0000 !important;
  }

  .chip-font {
    color: #ff0000;
  }
}

.row-hover {
  cursor: pointer;
  table {
    tr {
      &:hover {
        td {
          .on-view-order-button {
            visibility: visible;
          }
        }
      }
      td {
        .on-view-order-button {
          visibility: hidden;
        }
      }
    }
  }
}

.order-management-page {
  .card-rounded {
    border-radius: 10px !important;
    border: 0;

    &.not-from-top {
      // border-top-left-radius: 0 !important;
      // border-top-right-radius: 0 !important;
      box-shadow: 0px 6px 6px #00000029 !important;
    }
  }

  .v-data-table {
    border: 0;
    box-shadow: none !important;
    border-radius: 0;
  }

  .refresh-icon {
    color: #161616 !important;
  }

  .refresh-btn {
    margin-top: 11px;
    transform: translateX(-2px);
  }
}

.status-filters-list {
  list-style: none;
  display: flex;
  padding-left: 0;
  margin: 0;

  li {
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    height: 50px;
    justify-content: center;
    text-transform: capitalize;
    width: 150px;

    &.active {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
    }
  }
}

.pending-status {
  background: #FFF2E3;
  color: #FD8800;
  display: inline-block;
  padding: 0 12px;
  line-height: 24px;
  height: 24px;
  border-radius: 20px;

  .indicator {
    background: #FD8800;
    border-radius: 20px;
    display: inline-block;
    height: 8px;
    margin-right: 3px;
    width: 8px;
  }
}

.bulk-order-push-dialog {
  .v-toolbar {
    background: #0E3B4D;
  }

  .custom-shipping-rate-input {
    &.v-input--is-disabled {
      opacity: .7;
    }
  }

  .push-order-btn {
    border-radius: 4px !important;
    height: 48px;
    font-weight: bold;

    &.v-btn--disabled {
      background: #ccc;
    }
  }
}
</style>
